import "jquery";
import "appdevresources/jquery-ui";
import "@fortawesome/fontawesome-free/js/all.min";
import "bootstrap";
import "popper.js";
import "trumbowyg";
import "trumbowyg/plugins/cleanpaste/trumbowyg.cleanpaste";
import "datatables.net-fixedheader-bs4";
import "select2";

// This is here to ensure that jQuery is available outside of webpack.
if (typeof global.jQuery === "undefined") {
    global.$ = global.jQuery = jQuery;
}
import "petition-js/searchBar.js";
import "appdevresources/navigation-menu-builder";

global.dd = window.console.log;