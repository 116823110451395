import common from "appdevresources/common"
import * as autoComplete from "searchautocomplete/search-autocomplete";

$(async function () {
    const searchForm = $("[name='search-form'].student-search");
    const studentPidm = searchForm.find("[name='selected-pidm']");
    const searchBox = searchForm.find("[name='student-search']");
    const prevSearchName = $("[name='previous-search-name']:first").val();
    const prevSearchId = $("[name='previous-search-id']:first").val();

    searchBox.val(prevSearchName);
    $("[name='student-search']").val(prevSearchName);
    studentPidm.val(prevSearchId);

    if (typeof studentsSearchList !== "undefined") {
        autoComplete.load(searchForm, studentsSearchList, {
            minLength: 5,
            showExpandedResults: true,
            submitOnSelect: true
        });
    }

    $("body").on("mousedown", function (e) {
        if (!$(e.target).parents("[name='search-form'].student-search").length && !$(e.target).parents("ul.ui-autocomplete").length) {
            searchBox.val(prevSearchName);
            studentPidm.val(prevSearchId);
        }
    });

    $("[name=student-search-button]").on("click", function (e) {
        e.preventDefault();
        search($(e.target).parents("form"));
    })

    searchBox.on("keydown", function (e) {
        if (e.which === 13) {
            search($(e.target).parents("form"));
        }
    });

    function search(activeForm) {
        const searchString = activeForm.find("[name='student-search']").val();
        const isNumericSearch = /^\d+$/.test(searchString);
        if (!isNumericSearch || (isNumericSearch && searchString.length !== 9)) {
            return false;
        }
        common.loadSpinner();
        activeForm.submit();
    }
});
