$(function() {
    // This is used by navigation when it goes into responsive mode so that
    // clicking on the hamburger icon will "slide" out the menu.
    $("[data-toggle=collapse-side]").on("click", function () {
        const sideCollapse = $(".side-collapse");
        const toggler = $(this);

        toggler.toggleClass("active");
        sideCollapse.addClass("in");
        if (toggler.hasClass("active")) {
            sideCollapse.removeClass("in");
        }

        if (!sideCollapse.hasClass("in")) {
            toggler.addClass("active");
            sideCollapse.one("msTransitionEnd transitionend", () => {
                $(document).one("click.sideCollapse, touch.sideCollapse", function(e) {
                    const target = e.target;
                    // If the user is interacting with elements inside of the
                    // menu, we do nothing. Otherwise, close the menu.
                    if (toggler.is(target) || sideCollapse.is(target) || sideCollapse.has(target).length === 0 || $(target).attr("data-toggle") === "modal") {
                        sideCollapse.addClass("in");
                        toggler.removeClass("active");
                        $(".nav-item-reset-remove").slideUp().remove();
                    }
                });
            });

            const additionalEle = $("#logout-dropdown-nav-items").find("a");
            $.each($.makeArray(additionalEle), function() {
                let link = document.createElement("a");
                link.href = $(this).attr("href") ? $(this).attr("href") : '';
                if ($(this).is("[data-toggle='modal']")) {
                    link.setAttribute("data-toggle", "modal");
                    link.setAttribute("data-target", $(this).attr("data-target"));
                }
                link.text = $(this).text();

                const navLinkLi = $("<li>").addClass("nav-item-reset-remove").addClass(this.classList.value);
                const navLinkA = navLinkLi.append(link);

                $(".side-collapse > nav > ul").append(navLinkA);
            });

            const additionalSubNavEle = $("#navigation-sub").find(".navbar-nav").find("li");
            if (additionalSubNavEle.length) {
                $("<ul class='nav navbar-nav dynamic-sub-nav nav-item-reset-remove'></ul>")
                    .appendTo($(".side-collapse > nav > ul > li.active:not(.nav-item-reset-remove)"));
            }
            $.each($.makeArray(additionalSubNavEle), function() {
                let link = document.createElement("a");
                link.href = $(this).find("a").attr("href");
                link.text = $(this).find("a").text();

                const navLinkLi = $("<li>").addClass("nav-item-reset-remove").addClass(this.classList.value);
                const navLinkA = navLinkLi.append(link);

                $(".dynamic-sub-nav").append(navLinkA);
            });

            /**
             * This section will add anything else that is in the logout dropdown nav items. i.e search boxes, or such... that might be hidden
             * from view on larger screens. just add the include-in-hamburger class to the div element and it will get tossed in here...
             */
            const additionalNonLinkEle = $("#logout-dropdown-nav-items").find(".include-in-hamburger");
            $.each($.makeArray(additionalNonLinkEle), function() {
                const navEle = $("<div>").append($(this));
                $(".side-collapse > nav").append(navEle);
            });
        }
    });

    $(window).resize(function() {
        $("body").trigger("click")
    });
});
